import React from 'react';
import { Layout, Typography, Row, Col, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;

const RefundPolicy = () => {
  const sections = [
    {
      title: 'Introduction',
      content: `At Dance Hall by Malliswari, we are committed to offering flexible options for our students while ensuring a smooth booking process that respects both student and teacher time. The following cancellation and refund terms apply to all bookings:`
    },
    {
      title: '1. Class Cancellations',
      content: null,
      subsections: [
        {
          title: 'Full Refund',
          content: 'If you cancel your class booking at least 24 hours before the scheduled class time, you are eligible for a full refund.'
        },
        {
          title: 'Partial Refund (Credit for Future Class)',
          content: 'If a class is canceled between 12 and 24 hours before the start time, a refund will not be issued, but the amount will be credited toward a future class booking. This credit can be applied to a class of equal or lesser value.'
        },
        {
          title: 'No Refund',
          content: 'Cancellations made within 4 hours of the class start time or if you do not attend the class (no-show), will not be eligible for a refund or credit.'
        }
      ],
      footer: 'These policies help ensure that instructors have adequate time to prepare for classes, and that other students have a fair opportunity to book classes in case of last-minute cancellations.'
    },
    {
      title: '2. Cancellation Process',
      content: 'Cancellations can be made directly through your booking account on our platform. Any cancellations outside of the designated windows will automatically follow the refund guidelines mentioned above.'
    },
    {
      title: '3. Payment & Refund Timelines',
      subsections: [
        {
          title: 'Refund Method',
          content: 'Refunds will be issued to the original payment method used for the booking.'
        },
        {
          title: 'Processing Time',
          content: 'For eligible refunds, please allow 1-2 business days for the refund to be processed by Dance Hall by Malliswari. Additional processing time may apply depending on your payment provider.'
        }
      ]
    },
    {
      title: '4. Class Rescheduling',
      content: 'Rescheduling is permitted up to 12 hours before the class start time. If rescheduling is not possible within this window, the above cancellation terms will apply.'
    },
    {
      title: '5. Exceptions and Additional Support',
      content: 'If you believe there are exceptional circumstances that warrant a refund outside of the stated terms, please contact our Customer Service team at support@dancehall.com. We review requests on a case-by-case basis to ensure fair and reasonable outcomes.'
    }
  ];

  return (
    <Layout>
      <Content style={{ 
        background: 'linear-gradient(180deg, #E1F0FF 0%, #FFFFFF 100%)',
        minHeight: '100vh',
        padding: '24px'
      }}>
        <Row justify="center">
          <Col xs={24} sm={24} md={20} lg={16} style={{ 
            background: 'white',
            padding: '32px',
            borderRadius: '8px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
          }}>
            <Link to="/" style={{ 
              display: 'inline-flex', 
              alignItems: 'center',
              marginBottom: '24px',
              color: '#1890ff'
            }}>
              <ArrowLeftOutlined style={{ marginRight: '8px' }} />
              Back to Home
            </Link>

            <Title level={1} style={{ 
              fontSize: '28px',
              marginBottom: '32px',
              textAlign: 'center'
            }}>
              Refund & Cancellation Policy
            </Title>

            {sections.map((section, index) => (
              <div key={index} style={{ marginBottom: '32px' }}>
                <Title level={2} style={{ fontSize: '20px' }}>{section.title}</Title>
                {section.content && (
                  <Paragraph style={{ marginBottom: '16px' }}>
                    {section.content}
                  </Paragraph>
                )}
                
                {section.subsections && section.subsections.map((subsection, subIndex) => (
                  <div key={subIndex} style={{ marginLeft: '24px', marginBottom: '16px' }}>
                    <Title level={3} style={{ fontSize: '16px' }}>{subsection.title}</Title>
                    <Paragraph>
                      {subsection.content}
                    </Paragraph>
                  </div>
                ))}

                {section.footer && (
                  <Paragraph style={{ marginTop: '16px', fontStyle: 'italic' }}>
                    {section.footer}
                  </Paragraph>
                )}
              </div>
            ))}

            <Divider />

            <Text type="secondary" style={{ display: 'block', textAlign: 'center' }}>
              Last Updated: Jan 22, 2025
            </Text>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default RefundPolicy; 
